<template>
  <div>
    <el-row>
      <el-col :span="+Grid">
        <component
          :is="blockName"
          v-if="blockName"
        ></component>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import parseLocation from 'parse-location';
import components from './config/vueBlock';
import Axios from './../../libs/axios'
import Vue from 'vue'
import api from './../../api'
Vue.prototype.$api = api(Axios)

export default {
  name: 'Module',
  components,
  data () {
    return {
      Grid: 24,
      blockName: ''
    };
  },
  mounted () {

  },
  created () {
    const { query = {} } = parseLocation(window.location.href);
    this.Grid = query.grid || 24;
    this.blockName = query.block;
  },
  methods: {},
};
</script>

<style scoped></style>
